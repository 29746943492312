<template>
  <section class="menu" id="menu">
    <div class="container menu_container">
      <!-- <h2 class="menu__title">меню</h2> -->
      <a
        href="https://eda.yandex.ru/r/poka_goracee_1624905150?placeSlug=poka_goryachee_jhpob"
        class="menu__card-link menu__card-link_main"
        target="_blank"
        >Заказать в&nbsp;Яндекс&nbsp;Еде</a
      >
      <a
        href="https://market-delivery.yandex.ru/r/poka_goracee_1624905150?placeSlug=poka_goryachee_x3sh8"
        class="menu__card-link menu__card-link_main menu__card-link_main_delivery"
        target="_blank"
        >Заказать в&nbsp;Деливери</a
      >
    </div>
    <!-- <div v-if="!pending" class="menu__tags-wrapper">
      <div
        ref="tags"
        :class="['menu__tags', { menu__tags_grabbing: grabActive }]"
        @mousedown="handlerMouseDown"
      >
        <div
          v-for="(tag, index) in menu"
          :key="index"
          :class="['menu__tag', { menu__tag_active: index === activeTag }]"
          @click="setActiveTag(index)"
          v-html="tag.title"
        />
      </div>
    </div>
    <div class="menu__cards-wrapper">
      <div ref="cards" class="menu__cards">
        <template v-if="activeMenu.length">
          <div
            v-for="(card, index) in activeMenu"
            :key="index"
            class="menu__card"
          >
            <div class="menu__card-header">
              <div class="menu__card-title" v-html="cutText(card.title, 30)"/>
              <div class="menu__card-price">{{ card.price }}&nbsp;₽</div>
            </div>
            <img :src="card.imageUrl" alt="" class="menu__card-image"/>
            <div class="menu__card-holder">
              <p class="menu__card-text" v-html="cutText(card.description, 50)"/>
              <div class="menu__card-footer">
                <a
                  href="https://eda.yandex.ru/moscow/r/poka_goracee_1624905150?placeSlug=poka_goracee_1624905150_presnenskaya_naberezhnaya_12"
                  class="menu__card-count"
                  target="_blank"
                >1</a
                >
                <a
                  href="https://eda.yandex.ru/moscow/r/poka_goracee_1624905150?placeSlug=poka_goracee_1624905150_presnenskaya_naberezhnaya_12"
                  class="menu__card-link"
                  target="_blank"
                >Заказать</a
                >
              </div>
            </div>
          </div>
        </template>
        <p v-else class="menu__card-empty">Список продуктов пуст</p>
      </div>
    </div> -->
  </section>
</template>

<script>
import {getMenu} from '@/api/endpoints.js';

export default {
  name: 'menu-block',
  data: () => ({
    startCoords: null,
    grabActive: false,
    dragStart: 0,
    scrollLeft: 0,
    activeTag: 0,
    menu: null,
    pending: false,
  }),
  mounted() {
    this.getMenu();
    document.addEventListener('mousemove', this.handlerMouseMove);
    document.addEventListener('mouseup', this.handlerMouseUp);
  },
  destroyed() {
    document.removeEventListener('mousemove', this.handlerMouseMove);
    document.removeEventListener('mouseup', this.handlerMouseUp);
  },
  computed: {
    activeMenu() {
      return this.menu?.[this.activeTag]?.products ?? [];
    },
  },
  methods: {
    async getMenu() {
      this.pending = true;
      const response = await getMenu();
      this.pending = false;
      this.menu = response.data;
    },
    setActiveTag(index) {
      this.activeTag = index;
      this.$refs.cards.scrollLeft = 0;
    },
    handlerMouseMove(e) {
      if (this.grabActive) {
        this.$refs.tags.scrollLeft = this.scrollLeft - e.x + this.dragStart;
      }
    },
    handlerMouseDown(e) {
      this.startCoords = e.x;
      this.grabActive = true;
      this.dragStart = e.x;
      this.scrollLeft = this.$refs.tags.scrollLeft;
    },
    handlerMouseUp() {
      this.grabActive = false;
    },
    cutText(text, maxLenght) {
      if (text.length > maxLenght) {
        return `${text.slice(0, maxLenght)}...`;
      }
      return text;
    },
  },
};
</script>

<style src="./menu.less" lang="less"/>
