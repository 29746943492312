import Vue from 'vue';

import PromoBlock from './promo/promo.vue';
import OfferBlock from './offer/offer.vue';
import DeliveryBlock from './delivery/delivery.vue';
import MenuBlock from './menu/menu.vue';
import SeasonBlock from './season/season.vue';
import ContactBlock from './contact/contact.vue';
import FooterBlock from './footer/footer.vue';

Vue.component('promo-block', PromoBlock);
Vue.component('offer-block', OfferBlock);
Vue.component('delivery-block', DeliveryBlock);
Vue.component('menu-block', MenuBlock);
Vue.component('season-block', SeasonBlock);
Vue.component('contact-block', ContactBlock);
Vue.component('footer-block', FooterBlock);
