<template>
  <div class="app">
    <page-main class="app__view" />
    <footer-block class="app__footer" />
  </div>
</template>

<script>
import PageMain from '@/pages/main/page-main.vue';

export default {
  components: {
    PageMain,
  },
};
</script>
