<template>
  <section :class="['promo', { 'promo_open-menu': isMenuOpen }]">
    <div class="container promo__container">
      <div class="promo__block promo__block_left">
        <div class="promo__content">
          <div class="promo__burger" @click="isMenuOpen = !isMenuOpen">
            <span v-for="(span, index) in 3" :key="index" />
          </div>
          <div class="promo__logotype">
            <img src="images/logo.svg" class="promo__logotype-img" />
          </div>
          <div v-if="!isMenuOpen" class="promo__inner">
            <div class="promo__title">
              домашняя еда<br />
              с доставкой на дом
            </div>
            <a
              href="https://eda.yandex.ru/moscow/r/poka_goracee_1624905150?placeSlug=poka_goracee_1624905150_presnenskaya_naberezhnaya_12"
              target="_blank"
              class="promo__button"
              >Заказать</a
            >
          </div>

          <div v-else class="promo__menu">
            <div class="promo__menu-item" @click="scrollTo('#offer')">
              О нас
            </div>
            <div class="promo__menu-item" @click="scrollTo('#delivery')">
              Зона доставки
            </div>
            <div class="promo__menu-item" @click="scrollTo('#menu')">Заказать</div>
            <!-- <div class="promo__menu-item" @click="scrollTo('#season')">
              Сезонное меню
            </div> -->
            <div class="promo__menu-item" @click="scrollTo('#footer')">
              Контакты
            </div>
          </div>
        </div>
      </div>
      <div class="promo__block promo__block_right">
        <div class="promo__slider">
          <div class="promo__wrapper">
            <div class="promo__slide"></div>
          </div>
        </div>
      </div>
      <div class="promo__box">
        <img src="images/promo/promo_bug.png" class="promo__box-img" />
      </div>
    </div>
  </section>
</template>

<script>
import animateScrollTo from 'animated-scroll-to';

export default {
  name: 'promo-block',
  data: () => ({
    isMenuOpen: false,
  }),
  methods: {
    scrollTo(selector) {
      animateScrollTo(document.querySelector(selector));
      this.isMenuOpen = false;
    },
  },
};
</script>

<style src="./promo.less" lang="less" />
