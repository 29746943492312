<template>
  <footer class="footer" id="footer">
    <div class="container footer__container">
      <div class="footer__right">
        <a
          href="https://plms.adj.st/r/poka_goracee_1624905150?adj_t=9pgt268_gt98lbw&adj_deeplink=&adj_campaign=RU-ALL-ALL&adj_adgroup=Lading&adj_creative=Poka_Goryacheee_launch"
          class="footer__button"
          target="_blank"
        >
          Скачать в&nbsp;App Store
        </a>
      </div>
      <div class="footer__left">
        <a
          href="https://plms.adj.st/r/poka_goracee_1624905150?adj_t=9pgt268_gt98lbw&adj_deeplink=&adj_campaign=RU-ALL-ALL&adj_adgroup=Lading&adj_creative=Poka_Goryacheee_launch"
          class="footer__button"
          target="_blank"
        >
          Скачать в&nbsp;Google Play
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footer-block',
};
</script>

<style src="./footer.less" lang="less" />
