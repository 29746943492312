<template>
  <section class="contact">
    <div class="container">
      <h2 class="contact__title">обратная связь</h2>
      <p class="contact__text">
        У&nbsp;вас есть вопросы или вы&nbsp;хотите поделиться с&nbsp;нами
        каким-то предложением? Пишите&nbsp;&mdash; ответим. Мы&nbsp;всегда
        открыты и&nbsp;готовы к&nbsp;общению.
      </p>
      <p class="contact__text">
        для связи с&nbsp;нами:
        <a class="contact__link" href="mailto:pokagoryachee@yandex.ru"
          >pokagoryachee@yandex.ru</a
        >
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'contact-block',
};
</script>

<style src="./contact.less" lang="less" />
