<template>
  <section class="season" id="season">
    <div class="container">
      <h2 class="season__title">сезонное меню</h2>
      <swiper :options="optionsPostersSlider" class="season__slider">
        <swiper-slide
          v-for="(slide, index) in slides"
          :key="index"
          class="season__card-wrapper"
        >
          <div class="season__card">
            <div class="season__card-header">
              <div class="season__card-title" v-html="slide.title" />
              <div class="season__card-price">{{ slide.price }}&nbsp;₽</div>
            </div>
            <img :src="slide.image" alt="" class="season__card-image" />
            <p class="season__card-text" v-html="slide.text" />
            <div class="season__card-footer">
              <a
                href="https://eda.yandex.ru/moscow/r/poka_goracee_1624905150?placeSlug=poka_goracee_1624905150_presnenskaya_naberezhnaya_12"
                class="season__card-count"
                target="_blank"
                >1</a
              >
              <a
                href="https://eda.yandex.ru/moscow/r/poka_goracee_1624905150?placeSlug=poka_goracee_1624905150_presnenskaya_naberezhnaya_12"
                class="season__card-link"
                target="_blank"
                >Заказать</a
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="season-button season-button-next" />
      <div class="season-button season-button-prev" />
      <div class="season-pagination" />
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'season-block',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    slides: [
      {
        title: 'Обед лёгкий',
        price: 1450,
        text: 'Обед из салата и супа на выбор',
        image: 'images/menu/menu_1.jpg',
      },
      {
        title: 'Обед тяжелый',
        price: 450,
        text: 'Обед из салата и супа на выбор',
        image: 'images/menu/menu_1.jpg',
      },
      {
        title: 'Обед лёгкий',
        price: 2450,
        text: 'Обед из салата и супа на выбор',
        image: 'images/menu/menu_1.jpg',
      },
    ],
  }),
  computed: {
    optionsPostersSlider() {
      return {
        wrapperClass: 'season__slider-wrapper',
        freeMode: {
          enabled: false,
        },
        breakpoints: {
          320: {
            spaceBetween: 80,
            centeredSlides: true,
            pagination: {
              el: '.season-pagination',
              type: 'bullets',
            },
            navigation: {
              nextEl: '.season-button-next',
              prevEl: '.season-button-prev',
            },
          },
          1024: {
            centeredSlides: false,
            slidesPerView: 'auto',
            spaceBetween: 20,
            pagination: {
              enabled: false,
            },
            navigation: {
              enabled: false,
            },
          },
        },
      };
    },
  },
};
</script>

<style src="./season.less" lang="less" />
