<template>
  <section class="offer" id="offer">
    <div class="container">
      <swiper :options="optionsPostersSlider" class="offer__slider">
        <swiper-slide
          v-for="(slide, index) in slides"
          :key="index"
          :class="[
            'offer__slide-wrapper',
            { 'offer__slide-wrapper_last': index === slides.length - 1 },
          ]"
        >
          <div class="offer__slide">
            <div class="offer__slide-title" v-html="slide.title" />
            <div class="offer__slide-text" v-html="slide.text" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="offer-button offer-button-next" />
      <div class="offer-button offer-button-prev" />
      <div class="offer-pagination" />
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'offer-block',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    slides: [
      {
        title: 'кухня',
        text: 'Мы&nbsp;готовим домашнюю еду по&nbsp;классическим рецептам русской кухни. Это значит,что пюрешка будет без комочков, а&nbsp;щи&nbsp;&mdash; сытными и&nbsp;наваристыми',
      },
      {
        title: 'наборы и&nbsp;порции',
        text: 'В&nbsp;меню вы&nbsp;найдёте большие порции, например, десяток блинчиков со&nbsp;сладкой начинкой. А&nbsp;также сможете выбрать комбо наборы с&nbsp;блюдами на&nbsp;весь день',
      },
      {
        title: 'доставка',
        text: 'Доставка заказов организована так, чтобы вы&nbsp;могли наслаждаться любимой едой с&nbsp;пылу, с&nbsp;жару',
      },
    ],
  }),
  computed: {
    optionsPostersSlider() {
      return {
        wrapperClass: 'offer__slider-wrapper',
        freeMode: {
          enabled: false,
        },
        breakpoints: {
          320: {
            spaceBetween: 20,
            centeredSlides: true,
            pagination: {
              el: '.offer-pagination',
              type: 'bullets',
            },
            navigation: {
              nextEl: '.offer-button-next',
              prevEl: '.offer-button-prev',
            },
          },
          767: {
            spaceBetween: 20,
            pagination: {
              enabled: false,
            },
            navigation: {
              enabled: false,
            },
          },
          1024: {
            spaceBetween: 10,
            slidesPerView: 'auto',
            pagination: {
              enabled: false,
            },
            navigation: {
              enabled: false,
            },
          },
        },
      };
    },
  },
};
</script>

<style src="./offer.less" lang="less" />
