<template>
  <main class="page-main">
    <promo-block class="page-main__promo" />
    <offer-block class="page-main__offer" />
    <delivery-block class="page-main__delivery" />
    <menu-block class="page-main__menu" />
    <!-- <season-block class="page-main__season" /> -->
    <contact-block class="page-main__contact" />
  </main>
</template>

<script>
export default {
  name: 'page-main',
};
</script>

<style src="./page-main.less" lang="less" />
