import axios from 'axios';

let instance;
export function getInstance() {
  if (!instance) {
    instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });

    instance.interceptors.request.use((request) => {
      return request;
    });

    instance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error.response);
      }
    );
  }

  return instance;
}
