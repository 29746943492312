<template>
  <section class="delivery" id="delivery">
    <div class="container">
      <h2 class="delivery__title">зона доставки</h2>
      <p class="delivery__text">
        Бесплатная доставка на&nbsp;первый заказ в&nbsp;Яндекс Еде
      </p>
      <div class="delivery__map">
        <iframe
          class="delivery__map-iframe"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A30f42429124420ac575d62fef3c55370fc2f4bb2bcbf0abf72c8e765e48b06dd&source=constructorLink"
          frameborder="0"
        />
        <img class="delivery__map-img" src="images/delivery/bug.png" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'delivery-block',
};
</script>

<style src="./delivery.less" lang="less" />
